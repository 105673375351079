<template>
  <b-row class="wrap-border">
    <!--    上证指数卡片-->
    <b-col
        lg="3"
        sm="6">
      <b-overlay
          id="overlay-sh"
          :show="sh_show"
          variant="light"
          opacity="1.0"
          blur="2px"
          rounded="sm">
        <stock-index-card
            :index-num="sh.index.num"
            :index-name="sh.index.name"
            :change="sh.index.change"
            :chart-data="sh.series"
            :color="sh.index.trend"
        />
      </b-overlay>
    </b-col>
    <!--    深证成指卡片-->
    <b-col
        lg="3"
        sm="6">
      <b-overlay
          id="overlay-sz"
          :show="sz_show"
          variant="light"
          opacity="1.0"
          blur="2px"
          rounded="sm"
      >
        <stock-index-card
            :index-num="sz.index.num"
            :index-name="sz.index.name"
            :change="sz.index.change"
            :chart-data="sz.series"
            :color="sz.index.trend"
        />
      </b-overlay>
    </b-col>
    <!--    创业板指卡片-->
    <b-col
        lg="3"
        sm="6">
      <b-overlay
          id="overlay-st"
          :show="st_show"
          variant="light"
          opacity="1.0"
          blur="2px"
          rounded="sm"
      >
        <stock-index-card
            :index-num="st.index.num"
            :index-name="st.index.name"
            :change="st.index.change"
            :chart-data="st.series"
            :color="st.index.trend"
        />
      </b-overlay>
    </b-col>
    <!--    科创50卡片-->
    <b-col
        lg="3"
        sm="6">
      <b-overlay
          id="overlay-st50"
          :show="st50_show"
          variant="light"
          opacity="1.0"
          blur="2px"
          rounded="sm"
      >
        <stock-index-card
            :index-num="st50.index.num"
            :index-name="st50.index.name"
            :change="st50.index.change"
            :chart-data="st50.series"
            :color="st50.index.trend"
        />
      </b-overlay>
    </b-col>

    <!--    热议与时间线卡片-->
    <b-col lg="9"
           sm="6">
      <b-card>
        <!-- body -->
        <b-card-body>
          <b-tabs>
            <b-tab
                active
                title="热议">
              <b-overlay
                  id="overlay-hot-discussions"
                  :show="discussions_show"
                  variant="light"
                  opacity="1.0"
                  blur="2px"
                  rounded="sm">
                <div
                    v-for="discussion in discussions.items">
                  <hot-discussion-card
                      :content="discussion.content"
                      :discussion_id="discussion.discussion_id"
                      :release_time="discussion.release_time"
                      :title="discussion.title"
                      :user_avatar="discussion.user_avatar"
                      :user_id="discussion.user_id"
                      :user_name="discussion.user_name"
                      :link="discussion.link">
                  </hot-discussion-card>
                </div>
                <b-button
                    size="sm"
                    variant="outline-primary"
                    v-on:click="refreshHotDiscussions">
                  <b-overlay
                      :show="more_discussions_overlay"
                      rounded="sm">
                    显示更多
                  </b-overlay>
                </b-button>
              </b-overlay>
            </b-tab>
            <b-tab
                title="时间线">
              <b-overlay
                  id="overlay-timeline"
                  :show="timeline_show"
                  variant="light"
                  opacity="1.0"
                  blur="2px"
                  rounded="sm">
                <app-timeline v-for="item in timeline.items">
                  <app-timeline-item
                      :subtitle="item.content"
                      :title="item.release_time">
                  </app-timeline-item>
                  <br>
                </app-timeline>

                <b-button
                    size="sm"
                    variant="outline-primary"
                    v-on:click="refreshNews">
                  <b-overlay
                      :show="more_news_overlay"
                      rounded="sm">
                    显示更多
                  </b-overlay>
                </b-button>


              </b-overlay>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </b-col>

    <!--    热股榜卡片-->
    <b-col lg="3"
           sm="6">
      <b-card>
        <b-card-header>
          <b-card-title>热股</b-card-title>
        </b-card-header>
        <!-- body -->

        <b-card-body>
          <b-overlay
              id="overlay-HOT"
              :show="hot_show"
              variant="light"
              opacity="1.0"
              blur="2px"
              rounded="sm"
          >
            <b-tabs
                pills
                align="center">
              <b-tab
                  title="1小时内" active>
                <b-list-group flush>
                  <b-list-group-item
                      v-for="line in oneHour"
                      :to="{name:'stock', params: {stockCode:line.code}}">
                    {{ line.name }}
                  </b-list-group-item>
                </b-list-group>

              </b-tab>
              <b-tab
                  title="24小时内">
                <b-list-group flush>
                  <b-list-group-item
                      v-for="line in twentyFourHours"
                      :to="{name:'stock', params: {stockCode:line.code}}">
                    {{ line.name }}
                  </b-list-group-item>
                </b-list-group>
              </b-tab>
            </b-tabs>
          </b-overlay>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  BCardText,
  BMedia,
  BImg,
  BOverlay,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import StockIndexCard from "@/views/Home/StockIndexCard";
import HotDiscussionCard from "@/views/Home/HotDiscussionCard";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BCardText,
    BMedia,
    BImg,
    BOverlay,
    StockIndexCard,
    HotDiscussionCard,
    AppTimeline,
    AppTimelineItem,
    BButton,
    BSpinner,
  },
  data() {
    return {
      sh: {
        "series": [{
          "name": "",
          "data": []
        }], "index": {"num": 0, "change": "0", "name": "null", "trend": "rise"}
      },
      sz: {
        "series": [{
          "name": "",
          "data": []
        }], "index": {"num": 0, "change": "0", "name": "null", "trend": "rise"}
      },
      st: {
        "series": [{
          "name": "",
          "data": []
        }], "index": {"num": 0, "change": "0", "name": "null", "trend": "rise"}
      },
      st50: {
        "series": [{
          "name": "",
          "data": []
        }], "index": {"num": 0, "change": "0", "name": "null", "trend": "rise"}
      },
      sh_show: true,
      sz_show: true,
      st_show: true,
      st50_show: true,
      hot_show: true,
      discussions_show: true,
      timeline_show: true,
      more_news_overlay: false,
      more_discussions_overlay: false,
      oneHour: [],
      twentyFourHours: [],
      discussions: [],
      timeline: [],
    }
  },
  created() {
    this.getStockIndexCard()
    this.getOneHourHot()
    this.getTwentyFourHourHot()
    this.getHotDiscussions()
    this.getNews()
  },
  methods: {
    getOneHourHot() {
      this.$http.get('/api/data/get_hot?hours=1')
          .then(
              response => {
                this.oneHour = response.data.data
                this.hot_show = false
              })
    },
    getTwentyFourHourHot() {
      this.$http.get('/api/data/get_hot?hours=24')
          .then(
              response => {
                this.twentyFourHours = response.data.data
              })


    },
    getStockIndexCard() {
      this.$http.get('/api/data/get_stock_index_cards?type=01')
          .then(
              response => {
                this.sh = response.data.data
                this.sh_show = false
              })

      this.$http.get('/api/data/get_stock_index_cards?type=02')
          .then(
              response => {
                this.sz = response.data.data
                this.sz_show = false
              })

      this.$http.get('/api/data/get_stock_index_cards?type=03')
          .then(
              response => {
                this.st = response.data.data
                this.st_show = false
              })

      this.$http.get('/api/data/get_stock_index_cards?type=04')
          .then(
              response => {
                this.st50 = response.data.data
                this.st50_show = false
              })
    },
    getHotDiscussions() {
      this.$http.get('/api/data/get_hot_discuss')
          .then(
              response => {
                this.discussions = response.data.data
                this.discussions.items.forEach(element => element.link = "https://xueqiu.com/" + element.user_id + "/" + element.discussion_id)
                this.discussions_show = false
              })
    },
    getNews() {
      this.$http.get('/api/data/get_news')
          .then(
              response => {
                this.timeline = response.data.data
                this.timeline_show = false
              })
    },
    refreshHotDiscussions() {
      this.more_discussions_overlay = true
      this.$http.get('/api/data/get_hot_discuss?max=' + this.discussions.next_max)
          .then(
              response => {
                let res = response.data.data.items
                res.forEach(element => element.link = "https://xueqiu.com/" + element.user_id + "/" + element.discussion_id)
                this.discussions.items = [...this.discussions.items, ...res]
                this.discussions.next_max = response.data.data.next_max
                this.discussions_show = false
                this.more_discussions_overlay = false
              })
    },
    refreshNews() {
      this.more_news_overlay = true
      this.$http.get('/api/data/get_news?max=' + this.timeline.next_max)
          .then(
              response => {
                this.timeline.items = [...this.timeline.items, ...response.data.data.items]
                this.timeline.next_max = response.data.data.next_max
                this.timeline_show = false
                this.more_news_overlay = false
              })
    },
  },
}


</script>

<style>

</style>
